import React, { useContext } from "react"
import { PageLayout, PageTitle } from "../components"
import { Container, Image } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import { ThemeContext, SEO } from "../utils"
import ReactRoundedImage from "react-rounded-image";

export default ({ data }) => {
  const MediaLink = ({ title, author, link }) => (
    <li key={title} style={{ color: "gray" }}>
      <a rel="noopener noreferrer" href={link}>
        {title}
      </a>
      &nbsp;-<i>{author}</i>
    </li>
  )

  const {
    author,
    occupation,
    readingList,
    showsList,
    designations,
    unemployed,
  } = data.site.siteMetadata
  const { toString } = useContext(ThemeContext)

  const bookLinks = readingList.map(book => MediaLink(book))
  const showLinks = showsList.map(show => MediaLink(show))

  return (
    <PageLayout>
      <SEO title="About Me" />
      <PageTitle title="About Me" />
      <Container>
        
        <Image
        style={{
          borderRadius: 75/ 2,
          height: 600,
          width: 450
        }}
          
          src={`../../icons/ProfilePic.jpg`}
          
        
        />
        
        <article className="w-75 m-auto pt-2 text-justify">
          <p className="text-center">
            {designations.map((attr, i) => (
              <span key={attr}>
                &nbsp;<b>{attr}</b>&nbsp;
                {i < designations.length - 1 && <>||</>}
              </span>
            ))}
          </p>
          <p className="i-5 mt-4 pt-2">
          I am currently a junior Mechanical Engineering student at Texas A&M University, and I am interested in developing a career in the engineering, automotive, or computing industry. I am also minoring in computer science and mathematics.

          </p>
          <p className="i-5">
          The numerous experiences I have gained from school, including my positions in various student organizations like Formula SAE, Professional Brotherhood of Engineers, and Sophomores Leading on Promoting Equality (SLOPE), have all helped me build engineering skills as well as interpersonal skills. My engineering skills have been developed from projects such as the Box Torsion Test, which I performed as an apprentice for FSAE, as well as the Fuel Economy and Performance model, which I completed during an automotive engineering course.

          </p>
          <p className="i-5">
          In addition to these engineering experiences, I have also had the opportunity to fulfill my duty to serve the College Station community by coordinating multiple volunteering events for SLOPE members to attend. I will be continuing my role in SLOPE as its president for 2021-2022, and I aim to increase its impact not only on A&M’s campus, but also in the College Station community.
          </p>
          <p className="i-5">
          My interest in the automotive industry is what initially drove me to study mechanical engineering, but during my undergraduate studies I have grown to appreciate all facets of the engineering industry, especially areas that focus on technology and software. I hope to use the knowledge and skills I’ve gained as a student to better the world around me in any way possible, and in the meantime I hope to inspire others to do so as well.

          </p>
          <p className="i-5">
          Some of my personal interests include music production, bodybuilding, gaming, and cars.

              </p>
        </article>
        <article className="w-75 m-auto">
          {unemployed && (
            <>
             
             
            </>
          )}
          <hr />
         <h4>
            YouTube
            </h4>
            <div>
           <iframe src="https://www.youtube.com/embed/?listType=user_uploads&list=thetoolrepairman" width="500" height="300"></iframe>
           </div>
        
        </article>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        unemployed
        occupation
        author
        designations
        readingList {
          title
          author
          link
        }
        showsList {
          title
          author
          link
        }
      }
    }
  }
`
